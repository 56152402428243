@use "./../../scss/" as *;

.form-group {
  display: flex;
  input {
    font-size: 18px;
    padding: 22px 29px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: none;

    &:focus {
      border: 0;
      box-shadow: none;
    }
  }
  button {
    font-size: 20px;
    padding: 20px 97px 20px 59px;
    background: $main-color;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: $main-color;
    overflow: hidden;
    position: relative;

    @include mobile {
      padding: 20px 30px;
      display: flex;
      justify-content: center;
    }

    &::after {
      content: "";
      position: absolute;
      top: -110%;
      left: -210%;
      width: 200%;
      height: 200%;
      opacity: 0;
      background: rgba(255, 255, 255, 0.13);
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.13) 0%,
        rgba(255, 255, 255, 0.13) 77%,
        rgba(255, 255, 255, 0.5) 92%,
        rgba(255, 255, 255, 0) 100%
      );
      transition: all 0.7s;
    }

    &:hover {
      background: $main-color;
      border-color: $main-color;

      &:after {
        opacity: 1;
        top: -20%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: linear;
      }
    }
  }
}

.comment__form {
  margin-top: 30px;
  .form-group {
    margin-bottom: 19px;
    textarea {
      height: 230px;
      border-radius: 10px;
      padding: 18px 22px;
      font-size: 18px;
      border: none;

      &:focus {
        border: none;
        box-shadow: none;
      }
    }

    input {
      border-radius: 10px;
      padding: 17px 24px;

      margin-right: 17px;
    }

    @include mobile {
      flex-wrap: wrap;
      input {
        margin-bottom: 20px;
        margin-right: 0;
      }
    }
  }
  button.btn-action.style-2 {
    padding: 18px 56px;
    color: #fff;
    font-size: 20px;
    margin-top: -2px;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $main-color;
    }
  }
}

.contact__form {
  padding-top: 51px;
  .form-group {
    margin-bottom: 17px;
    input,
    select {
      border-radius: 10px;
      margin-right: 20px;
      font-size: 18px;

      padding: 17px 24px;
    }
    textarea {
      height: 230px;
      border-radius: 10px;
      font-size: 18px;
      padding: 17px 24px;
    }
  }
  button.btn-action.style-2 {
    padding: 18px 56px;
    color: #fff;
    font-size: 20px;
    margin-top: 1px;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $main-color;
    }
  }
}
